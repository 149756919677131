.events-body {
    width: 100%;
    background-color: #222;
    font-family: 'Staatliches', sans-serif;
    font-weight: 500;
    background-color: rgb(24, 23, 31);
    margin-bottom: 150px;
}

.events-row {
    display: flex;
    margin: auto;
    max-width: 1400px;
    width: 80%;
    position: relative;
    align-items: center;
    height: 260px;
    margin-top: 15px;
    -moz-box-shadow: -3px -3px -5px -6px;
    -webkit-box-shadow: -3px -3px -3px -5px;
    box-shadow: 3px 3px 5px 5px rgba(0,0,0,0.35);
}

.events-row:nth-child(1) {
    margin-top: 120px;
}

.events-row:last-child {
    margin-bottom: 50px;
}

.events-row-column:nth-child(1) {
    flex: 1;
    background-color: rgb(24, 23, 31);
    height: 100%;
}

.events-row-column:nth-child(2) {
    flex: 1;
    height: 100%;
}

.events-column-title {
    font-size: 26px;
    color: rgb(255, 186, 57);
    font-weight: 700;
    width: 100%;
    padding-top: 30px;
    text-align: center;
    margin: auto;
}

.events-column-secondtitle {
    font-size: 22px;
    color: seashell;
    font-weight: 700;
    width: 100%;
    padding-top: 1px;
    text-align: center;
    margin: auto;
}

.events-column-link {
    font-size: 18px;
    color: white;
    font-weight: 700;
    width: 100%;
    padding-top: 10px;
    text-align: center;
    margin: auto;
    display: block;
}

.events-column-text {
    font-family: 'Assistant',sans-serif;
    white-space: pre-line;
    font-size: 15px;
    line-height: 1.3;
    color: #a5a5a4;
    font-weight: 500;
    text-align: center;
    width: 70%;
    border-color:rgb(70, 64, 60);
    margin: auto;
    padding-top: 10px;
}

.events-column-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media screen and (max-width: 1200px) {
    .events-row-column:nth-child(1) {
        --min-width: 500px;
    }
    .events-column-title {
        padding-top: 20px;
    }
}

@media screen and (max-width: 992px) {
    .events-row {
        flex-direction: column;
        height: 30%;
    }

    .events-column-img {
        width: 100%;
        height: 100%;
        padding: 15px 0px 10px 0px;
        object-fit: cover;
        object-position: center;
    }

    .events-column-text:nth-child(2) {
        width: 95%;
        height: 50%;
    }

    .events-row-column:nth-child(1) {
        width: 94%;
        min-width: 0px;
        order: 1;
    }
    .events-row-column:nth-child(2) {
        width: 94%;
        order: 2;
    }

    .events-row:nth-child(1) {
        margin-top: 80px;
    }

    .events-column-title {
        font-size: 22px;
    }
    
    .events-column-secondtitle {
        font-size: 18px;
    }
    
    .events-column-link {
        font-size: 16px;
        padding-bottom: 10px;
    }
    
    .events-column-text {
        font-size: 13px;
        width: 85%;
    }
}
