.leaflet-div-icon {
    background: transparent;
    border: none;
}  

.leaflet-popup-content {
  width: 150px;
}

.leaflet-popup-content-wrapper {
  background-color: rgb(24, 23, 31);
}

.leaflet-top {
  position: absolute;
  z-index: 499; /* was 1000 */
  pointer-events: none;
  top: 100px;
}

.leaflet-popup-content h1 {
  font-family: 'Staatliches', 'sans-serif';
  font-size: 26px;
  color: rgb(255, 186, 57);
  text-align: center;
}

.leaflet-popup-content b {
  font-size: 14px;
  color: white;
}

.leaflet-popup-content p {
  font-family: 'Assistant', 'sans-serif';
  font-size: 13px;
  color: white;
}

#map {
  height: calc(100% - 170px);
  width: calc(100% - 450px);
  z-index: 1;
  position: absolute !important;
}

.map-text-label {
  background-color: aliceblue;
  padding: 10px;
  width: 150px;
}

@media screen and (max-width: 800px) {
  #map {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  #map {
    height: calc(100% - 150px);
  }
}
