.contact-body {
    width: 100%;
    background-color: #222;
    font-family: 'Staatliches', sans-serif;
    font-weight: 500;
    flex: 1 0 auto;
}

.contact-info {
    display: flex;
    height: 100%;
}

.contact-us {
    position: absolute;
    display: block;
    z-index: 2;
    max-width: 450px;
    right: 0;
    height: calc(100% - 270px);
    padding-top: 100px;
    background-color: rgb(24, 23, 31);
    -webkit-box-shadow: 0px 0px 32px 4px rgba(0,0,0,0.21);
    -moz-box-shadow: 0px 0px 32px 4px rgba(0,0,0,0.21);
    box-shadow: 0px 0px 32px 4px rgba(0,0,0,0.21);
}

.contact-us-list-title {
    font-size: 24px;
    color:  rgb(255, 186, 57);
}

.contact-icon {
    color: rgb(255, 186, 57);
}

.contact-us-hide-btn {
    position: absolute;
    left: 5px;
    border: none;
    padding: 25px;
    font-size: 5px;
    border-radius: 10% 10% 10% 10%;
    background-color: white;
    cursor: pointer;
    outline: none;
    display: none;
}

.contact-us-hide-btn.smaller {
    position: absolute;
    left: 5px;
    z-index: 10;
    border-radius: 10% 10% 10% 10%;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 0px 0px 9px 4px rgba(0,0,0,0.32); 
    box-shadow: 0px 10px 13px -7px #000000, 0px 0px 9px 4px rgba(0,0,0,0.32);
}

.contact-us ul {
    list-style: none;
    font-size: 20px;
    padding: 0;
    color: white;
    display: flex;
    flex-direction: column;
    height: 80%;
}

.contact-us li {
    padding: 35px 80px 0px 75px;
    flex: 1;
    text-align: center;
}

.contact-us li p {
    padding: 0;
    margin: 0;
}

.contact-us li h2 {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 28px;
    margin: 0;
    color:  rgb(255, 186, 57);
    text-align: center;
    height: 100%;
}

.contact-us li a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .contact-info {
        flex-direction: column;
    }
    .contact-us {
        padding-top: 0;
        height: 300px;
        bottom: 170px;
        max-width: 100%;
    }
    .contact-us-list-title {
        font-size: 22px;
    }
    .contact-us ul {
        font-size: 14px;
        padding: 0;
        color: white;
        text-align: center;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 0;
        height: 100%;
    }
    .contact-us li:nth-child(1) {
        flex-basis: 100%;
    }
    .contact-us li {
        padding: 5px 45px 0px 45px;
        flex: 1;
        text-align: center;
    }
    .contact-us li h2 {
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 28px;
        margin: 0;
        text-align: center;
    }
    .contact-us-hide-btn {
        display: block;
    }

    .contact-us-hide-btn.smaller {
        bottom: 0px;
    }
    
    .contact-us.smaller {
        z-index: unset;
    }
}

@media screen and (max-width: 500px) {
   
    .contact-us-list-title {
        font-size: 20px;
    }

    .contact-us {
        bottom: 130px;
        width: 100%;
    }

    .contact-us ul {
        font-size: 14px;
        margin: 0;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .contact-us li:nth-child(1) {
        flex-basis: 0px;
        padding-top: 20px;
    }

    .contact-us li h2 {
        font-size: 24px;
        margin: 0;
    }

    .contact-us-hide-btn.smaller {
        bottom: 10px;
    }
}
