.shop-conditions-container input[type=checkbox] {
  height: 15px;
  width: 15px;
  display: inline;
}

.popup {
  position: relative;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.popup u {
  cursor: pointer;
  text-decoration: underline;
}

.popup .popuptext {
  font-family: 'Assistant',sans-serif;
  white-space: pre-line;
  overflow: auto;
  visibility: hidden;
  width: 320px;
  max-height: 300px;
  background-color: #18171f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  --padding: 8px 0;
  padding: 20px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -50px;
  text-align: left;
  font-size: 13px;
}

.popup .popuptext::after {
content: "";
position: absolute;
top: 100%;
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: #555 transparent transparent transparent;
}

.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}


@media screen and (max-width: 600px) {
  .popup .popuptext {
    bottom: 125%;
    left: 50%;
    margin-left: -100px;
  }
}

@media screen and (max-width: 530px) {
  .popup .popuptext {
    bottom: 125%;
    left: 50%;
    margin-left: -160px;
  }
}

@media screen and (max-width: 430px) {
  .popup .popuptext {
    bottom: 125%;
    left: 50%;
    width: 270px;
    margin-left: -160px;
  }
}

@media screen and (max-width: 350px) {
  .popup .popuptext {
    bottom: 125%;
    left: 55%;
    width: 250px;
    margin-left: -160px;
  }
}