@import url('https://fonts.googleapis.com/css?family=Advent+Pro:100,200,300,400,500,600,700&display=swap&subset=greek,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Staatliches&display=swap&subset=latin-ext');

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #18171f;
}
