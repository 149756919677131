.input-counter {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

.input-minus,
.input-plus {
  background: #f2f2f2;
  border-radius: 4px;
  min-width: 26px;
  padding: 3px 3px 3px 3px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
}

.input-counter label {
  width: 50px;
  background: white;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  padding-top: 2px;
  padding-bottom: 2px;
}
