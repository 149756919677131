.payment-success-body {
  width: 100%;
  flex: 1 0 auto;
  background-color: #333;
  color: #333;
  font-family: "Staatliches", sans-serif;
}

.payment-success-container {
  padding: 30px;
}

.payment-success-content {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  max-width: 950px;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 0px;
  border-radius: 4px 4px 0 0;
}

.payment-success-content:last-child {
  padding-bottom: 40px;
  border-radius: 0 0 4px 4px;
}

.payment-success-content h3 {
  margin: 0;
  padding-bottom: 7px;
}

.payment-success-header {
  background-color: #222;
  margin-top: 150px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 950px;
  height: 80px;
}

.payment-success-header h2 {
  color: white;
  padding-top: 26px;
  font-size: 24px;
  font-weight: 500;
}

.payment-success-table,
th,
td {
  font-size: 18px;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  background-color: solid black;
  font-family: "Staatliches", sans-serif;
}
.payment-success-table {
  border-collapse: collapse;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-left: auto;
  width: 100%;
  margin-right: auto;
  table-layout: fixed;
}

.payment-success-go-to-index {
  background-color: rgb(85, 130, 92);
  padding: 6px;
  font-weight: 500;
  font-family: "Staatliches", sans-serif;
  margin-top: 20px;
  color: white;
  border-radius: 3px;
  font-size: 16px;
  border: none;
  display: block;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  width: 140px;
}

@media screen and (max-width: 800px) {
  .payment-success-container {
    padding: 20px;
  }

  .payment-success-content {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .payment-success-header {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    margin-top: 120px;
  }

  .payment-success-header h2 {
    padding-left: 10px;
  }

  .payment-success-content h3 {
    margin: 0;
    padding-left: 10px;
  }
  .payment-success-content h4 {
    padding-left: 10px;
  }
  .payment-success-go-to-index {
    margin-left: 5px;
  }
}

@media screen and (max-width: 500px) {
  .payment-success-table,
  th,
  td {
    font-size: 13px;
    padding: 5px;
  }
  .payment-success-go-to-index {
    margin-left: 5px;
    font-size: 14px;
    width: 120px;
  }
}

@media screen and (max-width: 350px) {
  .payment-success-header {
    margin-top: 100px;
  }

  .payment-success-content h3 {
    margin: 0;
    padding-left: 10px;
  }

  .payment-success-table,
  th,
  td {
    font-size: 12px;
    padding: 5px;
  }
  .payment-success-go-to-index {
    margin-left: 5px;
    font-size: 13px;
    width: 120px;
  }
}
