.index-body {
  height: 100%;
  text-align: center;
  font-family: 'Assistant',sans-serif;
  font-size: 18px;
  margin: 0;
  padding: 0;
  margin-bottom: 150px;
}

.background-video {
  -webkit-transition-position: fixed;
  position: fixed;
}

video {
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  transition: 1s opacity;
}

.parallax-body {
  height: 100vh;
  position: relative;
}

.parallax-body h1 {
  z-index: 1;
  font-family: 'Staatliches',sans-serif;
  color: rgb(255, 186, 57);
  text-shadow: 2px 3px #030202;
  position: absolute;
  top: 40%;
  text-align: center;
  font-size: 35px;
  width: 100%;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
  
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }

.parallax-carousel {
  position: fixed;
  -webkit-transition-position: fixed;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color:#18171f;
}

.parallax-scroll {
  position: relative;
  background-color:rgb(24, 23, 31);
  font-size: 36px;
}

.parallax-title {
  font-size: 32px;
  padding-top: 35px;
  padding-bottom: 10px;
  color: white;
}

.levitating-arrow-body {
  position: absolute;
  top: 90%;
  width: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.levitating-arrow {
  border-radius: 1px;
  border: solid #fff;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: relative;
  animation-name: bounce;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes bounce {
  0%   { top: 0px; }
  50%  { top: 15px; }
  100% { top: 0px; }
}

@media screen and (max-width: 900px) {
  .parallax-body h1 {
    font-size: 23px;
    text-align: center;
    margin: 10px, 10px, 10px, 10px;
    text-shadow: 1px 2px #030202;
  }
  .levitating-arrow-body {
    top: 80%;
  }
}


/* .parallax-video-body h1 {
  color: seashell;
  position: absolute;
  top: 35%;
  text-align: center;
  font-size: 45px;
  width: 100%;
}
.parallax-video-body h1 span:nth-child(3) {
  font-size: 40px;
  color: white;
}
.parallax-video-body h1 span:nth-child(2) {
  font-size: 45px;
  margin-bottom: -5px;
  color: rgb(255, 186, 57);
}
.parallax-video-body h1 span:nth-child(4) i {
  font-style: normal;
}
.parallax-video-body h1 span {
  font-size: 25px;
  margin: 0;
  display: block;
  color: rgb(255, 186, 57);
} */

/* .parallax-video-body img {
  color: white;
  position: absolute;
  top: 47%;
  width: 150px;
} */