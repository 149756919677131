.info-body {
  width: 100%;
  background-color: rgb(24, 23, 31);
  font-family: 'Staatliches', sans-serif;
  font-weight: 500;
  flex: 1 0 auto;
  display: flex;
  margin-bottom: 170px;
}

.info-title {
  text-align: center;
  margin-top: 100px;
  z-index: 100;
  color: whitesmoke;
  width: 60%;
  align-self: center;
}

.info-title-2 {
  text-align: center;
  margin-top: 50px;
  z-index: 100;
  color: whitesmoke;
  width: 60%;
  align-self: center;
}

.info-list {
  font-size: 21px;
  text-align:center;
  color: rgb(255, 186, 57);
}

.info-description {
  font-family: 'Assistant',sans-serif;
  color: whitesmoke;
}

.info-body-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
}

.info-body-container li {
  padding: 10px;
  list-style: none;
  align-self: center;
  width: 55%;
}

.info-body-container li:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 45%;
  padding-top: 30px;
  border-bottom: 1.5px solid rgba(87, 86, 86, 0.61);
}

.info-body-container li p {
  padding: 10px;
  margin: 0; 
}

.info-body-container li a {
  color: white;
  font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
}

.info-row {
  display: flex;
  margin: auto;
  max-width: 1400px;
  width: 87%;
  position: relative;
  align-items: center;
  height: 260px;
  margin-top: 15px;
  -moz-box-shadow: -3px -3px -5px -6px;
  -webkit-box-shadow: -3px -3px -3px -5px;
  box-shadow: 3px 3px 5px 5px rgba(0,0,0,0.35);}

.info-row:nth-child(1) {
  margin-top: 45px;
}

.info-row:last-child {
  margin-bottom: 50px;
}
.info-row-column:nth-child(0) {
  flex: 1;
  height: 100%;
}
.info-row-column:nth-child(1) {
  flex: 1;
  height: 100%;
}
.info-row-column:nth-child(2) {
  flex: 1;
  height: 100%;
}
.info-row-column:nth-child(3) {
  flex: 1;
  height: 100%;
}

.info-column-title {
  font-size: 21px;
  color: rgb(255, 186, 57);
  font-weight: 700;
  width: 100%;
  padding-top: 20px;
  text-align: center;
  margin: auto;
}

.info-column-secondtitle {
  font-size: 20px;
  color: seashell;
  font-weight: 700;
  width: 100%;
  padding-top: 1px;
  text-align: center;
  margin: auto;
}

.info-column-link {
  font-size: 14px;
  color: white;
  font-weight: 700;
  width: 100%;
  padding-top: 10px;
  text-align: center;
  margin: auto;
  display: block;
}

.info-column-text {
  font-family: 'Assistant',sans-serif;
  white-space: pre-line;
  font-size: 15px;
  line-height: 1.1;
  color: whitesmoke;
  font-weight: 500;
  text-align: center;
  width: 85%;
  margin: auto;
  padding-top: 10px;
}

.info-column-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 1200px) {

  .info-body {
    display: block;
  }

  .info-body-container li {
     width: 70%;
  }

  .info-row {
    flex-direction: column;
    width: 90%;
    height: 70%;
  }

  .info-column-text:nth-child(2) {
    width: 95%;
  }
 
  .info-row-column:nth-child(1) {
    width: 94%;
    min-width: 0px;
    order: 1;
  }
  .info-row-column:nth-child(2) {
    width: 94%;
    order: 2;
  }
  .info-column-title {
    font-size: 20px;
    padding-top: 10px;
  }
  
  .info-column-secondtitle {
    font-size: 18px;
  }
  
  .info-column-text {
    font-size: 13px;
  }

  .info-column-link {
    font-size: 12px;
    padding-bottom: 17px;
  }
}

@media screen and (max-width: 1097px) {

  .info-body-container li {
    width: 75%;
  }
}

@media screen and (max-width: 749px) {

  .info-body-container li {
    width: 85%;
  }
}

@media screen and (max-width: 500px) {

  .info-row {
    width: 90%;
  }

  .info-body-container li {
    width: 85%;
  }

  .info-title {
    width: 80%;
    margin-top: 75px;
    align-self: center;
  }
}