
.carousel-body img {
    height: 100vh;
    object-fit: cover;
}

.slick-dots li button:before {
    color: rgb(255, 186, 57);
    font-size: 10px;
    margin-top: -40px;
}

.slick-dots li.slick-active button:before {
    color:rgb(255, 186, 57)!important;
}

.slick-prev {
    left: 70px;
    z-index: 1;
    color: rgb(255, 186, 57);
}

.slick-next {
    right: 70px;
}

.carousel-component {
    position: fixed;
}