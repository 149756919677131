.online-shop-body {
    width: 100%;
    font-family: 'Staatliches', sans-serif;
    flex: 1 0 auto;
    background-color:#18171f;
    
    margin-bottom: 150px;
}

.online-shop-parallax-img {
    height: 400px;
    padding-top: 350px;
    font-size: 25px;
    color: white;
    background-attachment: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    font-family: 'Staatliches',sans-serif;
    -moz-box-shadow: -3px -3px -5px -6px;
    -webkit-box-shadow: -3px -3px -3px -5px;
    box-shadow: 2px 5px 10px 0px rgba(0,0,0,0.25);
}

.online-shop-parallax-body {
    margin-top: 30px;
    background-color: rgb(24, 23, 31);
    font-size: 30px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 60px;
    border-radius: 8px;
    -moz-box-shadow: -3px -3px -5px -6px;
    -webkit-box-shadow: -3px -3px -3px -5px;
    box-shadow: 2px 7px 10px 0px rgba(0,0,0,0.35);
}

.online-shop-product-header h3 {
    font-size: 30px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 5px;
    width: 100%;
    text-align: center;
    color: white;
}

.online-shop-product-header p {
    font-size: 18px;
    font-weight: 100;
    width: 100%;
    color: white;
    line-height: 1.3;
    text-align: center;
    margin: auto;
    padding-bottom: 20px;
}

.online-shop-announcer { 
    color: white;
    padding: 43px 3px 43px 3px;
    font-size: 23px;
    font-weight: 500;
    text-align: center;
}

.online-shop-product-body h3 {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    color: white;
}

.online-shop-culture-images {
    flex: 1;
    position: relative;
    display: flex;
    height: 50px;
    width: 100px;
    align-items: center;
    margin-left: 210px;
    margin-top: 50px;
}

.online-shop-culture-images img {
    flex: 1;
    align-items: center;
}

@media screen and (max-width: 992px) {

    .online-shop-culture-images {
        flex-direction: column;
        margin-left: 100px;
        margin-bottom: 310px;
    }
    
    .online-shop-parallax-body {
        margin-top: 30px;
        background-color: rgb(24, 23, 31);
        font-size: 30px;
        width: 90%;
        max-width: 1000px;
        margin: auto;
        margin-bottom: 60px;
        border-radius: 8px;
        -moz-box-shadow: -3px -3px -5px -6px;
        -webkit-box-shadow: -3px -3px -3px -5px;
        box-shadow: 2px 7px 10px 0px rgba(0,0,0,0.35);
    }
}