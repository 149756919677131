.header-body {
  background-color: transparent;
  padding: 10px 10px;
  color: rgba(64, 73, 61, 0.52);
  background-color: transparent;
  text-align: center;
  font-weight: bold;
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
  transition: all 0.7s;
  z-index: 999 !important;
}

.header-logo {
  float: left;
  width: 20px;
  margin-top: -8px;
}

.header-logo img {
  width: 150px;
}

.header-button-container {
  margin: auto;
  width: 65%;
  display: flex;
  max-width: 1200px;
}

.header-button-container a {
  text-decoration: none;
  display: inline-block;
  color: white;
  text-align: center;
  padding: 20px;
  font-size: 18px;
  flex: 1;
  font-family: 'Staatliches', sans-serif;
}

.header-button-container a:nth-child(5) {
  white-space: nowrap;
}

.header-button-container a:last-child {
  white-space: nowrap;
}

div.scrollmenu a:hover {
  background-color: #777;
}

.header-smaller {
    background-color: #18171f;
    padding: 10px 10px;
    z-index: 10;
    -webkit-box-shadow: 0px 6px 10px -2px rgba(0,0,0,0.52);
    -moz-box-shadow: 0px 6px 10px -2px rgba(0,0,0,0.52);
    box-shadow: 0px 6px 10px -2px rgba(0,0,0,0.52);
    opacity: 0.97;
}

.header-smaller .header-logo img:first-child {
  display: none;
}

.header-smaller .header-logo img:last-child {
  display: block;
}

.header-logo img:last-child {
  display: none;
}

.header-basket-icon {
  font-size: 20px !important;
  position: relative !important;
}

.header-basket-icon-count {
  height: 21px;
  width: 21px;
  top: 8px;
  margin-left: -10px;
  position: absolute;
  border-radius: 50%;
  background-color: rgb(85, 130, 92);
}

.header-basket-icon-count p {
  font-style: normal;
  color: white;
  font-size: 14px;
  position: absolute;
  top: 25%;
  left: 50%;
  width: 50%;
  height: 30%;
  margin: -15% 0 0 -25%;
}

.header-smaller .header-button-container a {
  color: white;
  padding: 20px;
}

/* it's react-router-dom library active classname */
.header-button-container .active {
  color: rgb(255, 186, 57);
}

/* it's react-router-dom library active classname */
.header-smaller .header-button-container .active {
  color: rgb(255, 186, 57);
}

.header-localization-container {
  font-weight: 500;
  vertical-align: middle;
  padding: 20px 0px 20px 10px !important;
}

.header-localization-container p {
  display: inline;
  padding: 4px;
  cursor: pointer;
}

.header-localization-container p.active {
  color: rgb(255, 186, 57);
}

.header-button-container .header-dropdown-btn {
  display: none;
  cursor: pointer;
}

/* ---------- responsive ----------  */

@media screen and (max-width: 1200px) {
  
  .header-body {
    padding: 0px;
  }

  .header-button-container a {
    display: none;
    padding: 15px;
  }

  .header-smaller .header-button-container a {
    color: white;
    padding: 15px;
  }

  .header-button-container {
    width: 200px;
    float: right;
  }

  .header-button-container .header-dropdown-btn {
    display: block;
    font-size: 28px;
    background: none;
    color: white;
    border: none;
    margin: auto;
    padding: 15px;
  }

  .header-smaller {
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .header-localization-container {
    font-weight: 500;
    vertical-align: middle;
    padding: 0px 0px 10px 10px !important;
  }
  
  .header-smaller .header-logo img {
    width: 150px;
  }

  .header-button-container.responsive {
    position: relative;
    display: block;
    background-color: #18171f;
    opacity: 0.97;
    border-radius: 0 0 0  10px;
  }

  .header-button-container.responsive a {
    display: block;
  }

  .header-button-container.responsive a:not(:first-child) {
    margin: 0;
    padding-top: 0;
  }
}

@media screen and (max-width: 400px) {
  .header-button-container {
    width: 150px;
  }
}