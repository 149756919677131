.shop-list {
  display: flex;
  flex-direction: row;
}

.shop-list-item {
  background-color: white;
}

@media screen and (max-width: 992px) {
  .shop-list {
    flex-direction: column;
  }
}