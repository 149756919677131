.trails-body {
  font-weight: 500;
  font-family: "Staatliches", sans-serif;
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  margin-bottom: 150px;
}

.trails-map {
  flex: 1;
}

.trails-map img {
  max-width: 100%;
}

.trails-us {
  background-color: rgb(24, 23, 31);
  max-width: 400px;
}

.trails-us li h1 {
  color: rgb(255, 186, 57);
  font-size: 28px;
}

.trails-us-list-title {
  font-size: 11px;
  padding-bottom: 5px;
  color: white;
}

.trails-us li:nth-child(2) svg {
  color: rgba(85, 139, 47, 1);
  border-width: 0px;
  border-style: solid;
}

.trails-us li:nth-child(3) svg {
  color: rgba(0, 131, 143, 1);
  border-width: 0px;
  border-style: solid;
}

.trails-us li:nth-child(4) svg {
  color: rgba(183, 28, 28, 1);
  border-width: 0px;
  border-style: solid;
}

.trails-us li:nth-child(5) svg {
  color: #000000;
  border-width: 0px;
  border-style: solid;
}

.trails-us-hide-btn {
  position: absolute;
  left: 5px;
  border: none;
  padding: 25px;
  font-size: 5px;
  border-radius: 10% 10% 10% 10%;
  background-color: white;
  cursor: pointer;
  outline: none;
  display: none;
}

.trails-us-hide-btn.smaller {
  position: absolute;
  left: 5px;
  z-index: 10;
  border-radius: 10% 10% 10% 10%;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    0px 0px 9px 4px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 10px 13px -7px #000000, 0px 0px 9px 4px rgba(0, 0, 0, 0.32);
}

.trails-us ul {
  list-style: none;
  font-size: 20px;
  padding: 0;
  color: white;
  display: flex;
  flex-direction: column;
  height: 80%;
  line-height: 18px;
}

.trails-us li {
  padding: 5px 30px 0px 30px;
  flex: 1;
  text-align: center;
}

.trails-us li p {
  font-size: 18px;
  margin: 0;
}

.trails-us li p:last-child {
  font-family: 'Assistant', 'sans-serif';
  color: white;
  font-size: 16px;
  margin: 0;
}

.trails-us li h2 {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 28px;
  margin: 0;
  color: white;
  text-align: center;
  height: 100%;
}

.trails-us li a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .trails-body {
    flex-direction: column;
  }
}

@media screen and (max-width: 1000px) {
  .trails-info {
    flex-direction: column;
  }
  .trails-us {
    padding-top: 0;
    height: 310px;
    bottom: 170px;
    max-width: 100%;
  }
  .trails-us-list-title {
    font-size: 22px;
  }
  .trails-us ul {
    font-size: 14px;
    padding: 0;
    color: white;
    text-align: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0;
    --height: 100%;
  }
  .trails-us li:nth-child(1) {
    flex-basis: 100%;
  }

  .trails-us li {
    padding: 5px 40px 0px 40px;
    flex: 1;
    text-align: center;
  }

  .trails-us li h2 {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 25px;
    margin: 0;
    text-align: center;
  }
  .trails-us-hide-btn {
    display: block;
  }

  .trails-us-hide-btn.smaller {
    bottom: 0px;
  }

  .trails-us.smaller {
    z-index: unset;
  }
}

@media screen and (max-width: 500px) {
  .trails-us-list-title {
    font-size: 20px;
    padding-bottom: 4px;
  }

  .trails-us li h1 {
    font-size: 24px;
  }

  .trails-us {
    bottom: 130px;
  }

  .trails-us ul {
    font-size: 12px;
    line-height: 12px;
    margin: 0;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .trails-us ul p {
    font-size: 16px;
  }

  .trails-us ul a {
    font-size: 12px;
  }

  .trails-us li:nth-child(1) {
    flex-basis: 0px;
  }

  .trails-us-hide-btn.smaller {
    bottom: 10px;
  }

  .trails-us li h2 {
    font-size: 24px;
    margin: 0;
  }
}
