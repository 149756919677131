.partners-body {
  width: 100%;
  background-color: rgb(24, 23, 31);
  font-family: 'Staatliches', sans-serif;
  font-weight: 500;
  margin-bottom: 150px;
}

.partners-background-img {
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.partners-row {
  display: flex;
  margin: auto;
  max-width: 1400px;
  width: 80%;
  position: relative;
  align-items: center;
  height: 260px;
  margin-top: 15px;
  -moz-box-shadow: -3px -3px -5px -6px;
  -webkit-box-shadow: -3px -3px -3px -5px;
  box-shadow: 3px 3px 5px 5px rgba(0,0,0,0.35);}

.partners-row:nth-child(1) {
  margin-top: 120px;
}

.partners-row:last-child {
  margin-bottom: 50px;
}
.partners-row-column:nth-child(0) {
  flex: 1;
  height: 100%;
}
.partners-row-column:nth-child(1) {
  flex: 1;
  height: 100%;
}
.partners-row-column:nth-child(2) {
  flex: 1;
  height: 100%;
}
.partners-row-column:nth-child(3) {
  flex: 1;
  height: 100%;
}

.partners-column-title {
  font-size: 26px;
  color: rgb(255, 186, 57);
  font-weight: 700;
  width: 100%;
  padding-top: 30px;
  text-align: center;
  margin: auto;
}

.partners-column-secondtitle {
  font-size: 22px;
  color: seashell;
  font-weight: 700;
  width: 100%;
  padding-top: 1px;
  text-align: center;
  margin: auto;
}

.partners-column-link {
  font-size: 18px;
  color: white;
  font-weight: 700;
  width: 100%;
  padding-top: 10px;
  text-align: center;
  margin: auto;
  display: block;
}

.partners-column-text {
  font-family: 'Assistant',sans-serif;
  white-space: pre-line;
  font-size: 15px;
  line-height: 1.3;
  color: #a5a5a4;
  font-weight: 500;
  text-align: center;
  width: 85%;
  margin: auto;
  padding-top: 10px;
}

.partners-column-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 1200px) {
  .partners-body {
    height: 100%,
  }
  .partners-row-column:nth-child(1) {
      --min-width: 500px;
  }
  .partners-column-title {
      padding-top: 20px;
  }
}

@media screen and (max-width: 1092px) {
  .partners-row {
      flex-direction: column;
      height: 23%;
  }

  .partners-row:nth-child(1) {
    margin-top: 80px;
  }

  .partners-column-text:nth-child(2) {
      width: 95%;
  }
 
  .partners-row-column:nth-child(1) {
      width: 94%;
      min-width: 0px;
      order: 1;
  }
  .partners-row-column:nth-child(2) {
      width: 94%;
      order: 2;
  }
  .partners-column-title {
    font-size: 22px;
    padding-top: 10px;
  }
  
  .partners-column-secondtitle {
    font-size: 18px;
  }
  
  .partners-column-link {
    font-size: 16px;
    padding-bottom: 10px;
  }
  
  .partners-column-text {
    font-size: 13px;
  }
  
  .partners-column-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
