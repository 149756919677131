.tabs-body {
    width: 100%;
}

.tabs-button-container {
    overflow: hidden;
    background-color: rgb(24, 23, 31);
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}

.tabs-button-container button {
    background-color: rgba(255, 186, 57, 0.137);
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 7px;
    margin-left: 5px;
    margin-right: 5px;
    transition: 0.3s;
    flex: 1;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Staatliches',sans-serif;
    color: white;
}
  
.tabs-button-container button:hover {
    background-color: rgba(255, 186, 57, 0.658);
}
  
.tabs-button-container button.active {
    background-color: rgb(255, 186, 57);
    color: black;
}

@media screen and (max-width: 800px) {

    .tabs-button-container button {
        font-size: 15px;
        margin-left: 3px;
        margin-right: 3px;
        padding: 3px;
    }

}