.popup-body {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.79);
    z-index: 100;
}

.popup-inner {
    max-width: 440px;
    margin: 200px auto;
    background: #f2f2f2;
    border-radius: 5px;
}

.popup-inner-header {
    width: 100%;
    background-color: rgb(255, 186, 57);
}

.popup-inner-header h2 {
    font-size: 20px;
    margin: 0;
    font-weight: 500;
    color: black;
    padding: 15px;
    text-align: center;
}

.popup-inner-header button {
    float: right;
    outline: none;
    padding: 5px;
    margin: 10px;
}

.popup-inner-body {
    padding: 25px;
}

.popup-inner-body p:nth-child(1) {
    margin: 0;
    font-size: 19px;
    width: 100%;
    border-bottom: 1px solid #000000;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0.2px;
}

.popup-inner-body p:nth-child(2) {
    letter-spacing: 0.2px;
    font-size: 18px;
    padding-bottom: 10px;
    margin: 0;
}

.popup-input-counter-container .popup-input-counter {
    display: inline;
    float: left;
    padding-bottom: 15px;
}

.popup-input-counter-container p {
    display: inline-block;
    padding-top: 6px;
    float: right;
}

.popup-footer {
    width: 100%;
    background-color: rgb(78, 78, 78);
    bottom: 0;
    margin: auto;
    align-items: center;
    display: flex;
}

.popup-footer-go-basket {
    background-color: rgb(255, 186, 57);
    padding: 6px;
    font-weight: 500;
    font-family: 'Staatliches',sans-serif;
    color: black;
    border-radius: 3px;
    font-size: 16px;
    border: none;
    outline: none;
    flex: 1;
    margin: 12px;
    text-align: center;
    text-decoration: none;
}

.popup-footer-continue-shopping {
    background-color: white;
    padding: 6px;
    font-weight: 500;
    font-family: 'Staatliches',sans-serif;
    color: black;
    border-radius: 3px;
    font-size: 16px;
    border: none;
    outline: none;
    flex: 1;
    margin: 12px;
    text-align: center;
    text-decoration: none;
}