.openingtimes-body {
  width: 100%;
  background-color: #222;
  font-family: 'Assistant', sans-serif;
  font-weight: 500;
  flex: 1 0 auto;
  margin-bottom: 250px;
}

.openingtimes-background-img {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.openingtimes-body-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.openingtimes-infobox {
  font-family: 'Assistant',sans-serif;
  background-color: rgb(24, 23, 31);
  border-radius: 3px;
  position: relative;
  margin: auto;
  -webkit-box-shadow: 0px 0px 32px 4px rgba(0,0,0,0.21);
  -moz-box-shadow: 0px 0px 32px 4px rgba(0,0,0,0.21);
  box-shadow: 0px 0px 32px 4px rgba(0,0,0,0.21);
  margin-top: 140px;
  width: 100%;
  max-width: 800px;
}

.openingtimes-infobox ul {
  list-style: none;
}

.openingtimes-infobox li {
  padding: 5px;
}

.openingtimes-infobox li p {
  margin: 0;
}

.openingtimes-infobox h1 {
  font-family: 'Staatliches',sans-serif;
  font-size: 30px;
  color:rgb(255, 186, 57);
  padding-top: 10px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  
  .openingtimes-body-container {
    display: block;
  }
  .openingtimes-infobox {
    border-radius: 0;
  }
}