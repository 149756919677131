.shop-contact-form-body {
    width: 90%;
    margin: auto;
    font-family: 'Staatliches', sans-serif;
    padding-bottom: 12px;
    border-radius: 5px;
}

.shop-contact-form-body h2 {
    font-size: 25px;
    font-weight: 500;
    margin: 0;
    width: 100%;
    text-align: left;
    padding-bottom: 10px;
}

.shop-contact-form-body label {
    display: block;
    padding: 5px;
    font-size: 17px;
    float: left;
}

.shop-contact-form-body input {
    display: block;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    font-size: 18px;
    border: 0.5px solid rgb(201, 201, 201);
    border-radius: 2px;
    width: 100%;
}

.shop-contact-form-body button {
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    font-family: 'Staatliches', sans-serif;
    font-size: 16px;
    color: black;
    background-color: #ffba39;
}

.shop-contact-form-body button:hover {
    border: none;
    background-color: #f9af26;
}

.shop-contact-form-body select {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 5px;
    width: 100%;
    border: 0.5px solid #c9c9c9;
}

.shop-contact-form-body img {
    margin-top: 30px;
    width: 100%;
}

@media screen and (max-width: 500px) {
    .shop-contact-form-body input[type=button] {
        width: 100%;
    }

    .shop-contact-form-body input {
        width: 98%;
    }
}