.item-body {
  width: 100%;
  margin: auto;
  font-family: "Staatliches", sans-serif;
  padding-top: 12px;
  padding-bottom: 12px;
}

.item-body table {
  border-collapse: collapse;
  width: 100%;
  background-color: #f2f2f2;
  table-layout: fixed;
}

.item-body tr {
  border-bottom: 2px solid #ffffff;
}

.item-body thead tr:nth-child(1) {
  border-bottom: 2px solid;
  border-color: #888;
}

.item-body th {
  text-align: left;
  font-size: 19px;
  font-weight: 500;
  padding: 18px;
}

.item-body th:nth-child(2) {
  text-align: center;
}

.item-body th:nth-child(3) {
  text-align: center;
}

.item-body th:last-child {
  text-align: right;
}

.item-body td:last-child {
  text-align: right;
}

.item-body td:nth-child(2) {
  text-align: center;
}

.item-body td:nth-child(3) {
  text-align: center;
}

.item-body td {
  border-bottom: 1px solid #ffffff;
  text-align: left;
  font-size: 17px;
  padding: 18px;
}

@media screen and (max-width: 800px) {
  .item-body table {
    border: 0;
  }

  .item-body table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .item-body table tr {
    border-bottom: 4px solid white;
    display: block;
  }

  .item-body table td {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
  }

  .item-body table td::before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-size: 17px;
  }

  .item-body table td:last-child {
    border-bottom: 0;
  }

  .item-body td:nth-child(2) {
    text-align: right;
  }

  .item-body td:nth-child(3) {
    text-align: right;
  }

  .item-body tfoot td:not(:last-child) {
    display: none;
  }
}
