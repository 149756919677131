.shop-list-body {
  background-color: rgb(24, 23, 31);
}

.shop-list-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 10px;
  margin-top: 8px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
  background-color: rgb(24, 23, 31);
  border: 0.1px solid rgba(255, 186, 57, 0.422);
}

.shop-list-column.disabled {
  opacity: 0.6;
}

.shop-list-column-img-container {
  margin-top: 20px;
  align-self: center;
}

.shop-list-icon {
  font-size: 55px;
  color: rgb(255, 186, 57);
}

.shop-list-column-img {
  width: 100%;
  height: 50%;
  background-color: rgb(24, 23, 31);
}

.shop-list-no-sale {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #ffa500;
  opacity: 0.9;
  color: #000000;
  text-align: center;
  left: 50%;
  top: 63%;
  padding-top: 2px;
  transform: translate(-50%, -50%);
}

.shop-list-column-title {
  font-size: 20px;
  text-align: center;
  color: white;
  font-weight: 500;
  margin: auto;
  height: 8%;
}

.shop-list-column-text {
  font-size: 15px;
  font-family: "Assistant", sans-serif;
  text-align: center;
  line-height: 1.3;
  color: whitesmoke;
  font-weight: 500;
  height: 9%;
  padding: 0px 20px 35px 20px;
}

.shop-list-form {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px 20px 25px;
}

.shop-list-column-price {
  font-size: 20px;
  margin: 0;
  line-height: 1.3;
  color: rgb(255, 186, 57);
  font-weight: 500;
}

.shop-list-column-button {
  background-color: rgb(255, 186, 57);
  padding: 5px;
  font-weight: 500;
  font-family: "Staatliches", sans-serif;
  color: black;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  outline: none;
  cursor: pointer;
}

.trainings-list-column {
  flex: 1;
  border-radius: 1px;
  margin-top: 12px;
  margin-left: 4px;
  margin-right: 4px;
  background-color: rgb(24, 23, 31);
  -webkit-box-shadow: 2px 7px 16px 0px rgba(177, 96, 96, 0.15);
  -moz-box-shadow: 2px 7px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 2px 1px rgba(255, 186, 57, 0.45);
}

.trainings-list-column-title {
  font-size: 20px;
  color: white;
  text-align: center;
  font-weight: 500;
  margin: auto;
  height: 5%;
  padding: 10px 20px 10px 20px;
}

.trainings-list-column-text {
  font-size: 15px;
  font-family: "Assistant", sans-serif;
  line-height: 1.3;
  color: whitesmoke;
  font-weight: 500;
  height: 52%;
  padding: 0px 20px 30px 20px;
}

.trainings-list-form {
  background-color: rgb(24, 23, 31);
  padding: 0px 15px 0px 15px;
}

.trainings-list-column-price {
  font-size: 20px;
  margin: 0;
  line-height: 1.3;
  color: rgb(255, 186, 57);
  font-weight: 500;
  display: inline-block;
  margin-left: 20px;
  padding-bottom: 10px;
}

.rental-list-column {
  flex: 1;
  border-radius: 1px;
  margin-top: 8px;
  margin-bottom: 5px;
  margin-left: 6px;
  margin-right: 6px;
  padding-bottom: 25px;
  background-color: rgb(24, 23, 31);
  -webkit-box-shadow: 2px 7px 16px 0px rgba(177, 96, 96, 0.15);
  -moz-box-shadow: 2px 7px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 2px 1px rgba(255, 186, 57, 0.45);
}

.rental-list-column.disabled {
  opacity: 0.6;
}

.rental-list-column-title {
  font-size: 20px;
  text-align: center;
  color: white;
  font-weight: 500;
  margin: auto;
  height: 8%;
  padding: 10px 20px 5px 20px;
}

.rental-list-column-text {
  font-size: 14px;
  text-align: center;
  font-family: "Assistant", sans-serif;
  line-height: 1.3;
  color: whitesmoke;
  font-weight: 500;
  height: 9%;
  padding: 0px 0px 10px 0px;
}

.rental-list-form {
  background-color: rgb(24, 23, 31);
  padding: 10px 15px 0px 15px;
}

.rental-list-column-price {
  font-size: 20px;
  margin: 0;
  line-height: 1.3;
  color: rgb(255, 186, 57);
  font-weight: 500;
  display: inline-block;
  margin-left: 20px;
  padding-bottom: 10px;
}

.rental-list-no-sale {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #ffa500;
  color: #000000;
  text-align: center;
  padding-top: 2px;
}

@media screen and (max-width: 992px) {
  .shop-list-icon {
    font-size: 50px;
  }
}

@media screen and (max-width: 600px) {
  .shop-list-column-button {
    padding: 6px;
    width: 120px;
  }

  .shop-list-icon {
    font-size: 50px;
  }

  .shop-list-column-text {
    font-size: 14px;
    padding: 0px 20px 10px 20px;
  }

  .shop-list-form {
    margin-top: 20px;
  }

  .rental-list-column {
    margin-top: 15px;
  }

  .rental-list-column-text {
    padding: 0px 20px 10px 20px;
  }

  .rental-list-form {
    padding: 100px 15px 0px 15px;
  }

  .rental-list-column-price {
    margin-left: 15px;
  }

  .trainings-list-column-text {
    padding: 0px 20px 20px 20px;
  }
}
