.footer-body {
    width: 100%;
    background-color: rgb(24, 23, 31);
    padding-top: 20px;
    text-align: center;
    font-family: 'Staatliches', sans-serif;
    height: 150px;
    z-index: 1;
    -moz-box-shadow: -3px -3px -5px -6px;
    -webkit-box-shadow: -3px -3px -3px -5px;
    box-shadow: 2px 7px 16px 10px rgba(0,0,0,0.35);
    position: absolute;
    bottom: 0;
}

.footer-inside-container {
    display: flex;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    border-bottom: 1px solid #a5a5a4;
}

.footer-body ul {
    list-style: none;
    padding: none;
    font-size: 15px;
    padding: 0;
    margin: 0;
}

.footer-body li {
    padding: 0;
}

.footer-body a {
    text-decoration: none;
    color: #a5a5a4;
    -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
}

.footer-body a:hover {
    color: #e6e6e6;
}

.footer-left {
    width: 50%;
    flex: 1;
    padding-top: 10px;
}

.footer-right {
    width: 50%;
    flex: 1;
}

.footer-inside-container h3 {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
    padding: 2px;
    color: #e6e6e6;
}

.footer-copyright {
    font-size: 13px;
    font-weight: 500;
    padding: 10px;
    color: #a5a5a4;
}


.footer-credits {
    font-size: 13px;
    font-weight: 500;
    padding: 30px;
    color: #a5a5a4;
    display: none;
}

.footer-copyrightmedia {
    font-size: 13px;
    font-weight: 500;
    padding: 25px;
}

.footer-row-icon {
    font-size: 25px;
}

@media screen and (max-width: 500px) {
    .footer-body {
        padding-top: 22px;
        height: 130px;
    }

    .footer-inside-container {
        padding: 5px 0px 5px 0px;
    }

    .footer-copyright {
        font-size: 10px;
        padding: 5px;
    }

    .footer-body ul {
        font-size: 12px;
    }
    .footer-inside-container h3 {
        font-size: 16px;
    }
    
}