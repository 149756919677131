.card-row-body {
    padding-top: 40px;
    padding-bottom: 50px;;
}

.card-row {
    display: flex;
    padding-top: 20px;
}

.card-row-column {
    flex: 1;
    color: seashell;
    padding: 35px;
    margin: 7px;
}

.card-row-title {
    font-size: 40px;
    font-family: 'Staatliches',sans-serif;
    text-align: center;
    color: seashell;
    font-weight: 700;
    width: 60%;
    padding: 30px 0 30px 0;
    border-color:seashell;
    margin-left: auto;
    margin-right: auto;
}

.card-row-column-title {
    font-size: 22px;
    font-family: 'Staatliches',sans-serif;
    color: seashell;
    font-weight: 700;
    border-color:seashell;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 10px;
}

.card-row-column-text {
    font-size: 14px;
    line-height: 1.5;
    color: seashell;
    font-weight: 500;
    width: 85%;
    border-color:seashell;
    margin-left: auto;
    margin-right: auto;
}

.card-row-column-img {
    width: 100%;
    max-height: 90%;
    -webkit-box-shadow: 5px 7px 7px 5px rgba(245, 175, 47, 0.45);
    -moz-box-shadow: 5px 7px 7px 5px rgba(245, 175, 47, 0.45);
    box-shadow: 0px 0px 3px 3px rgba(245, 175, 47, 0.85);
}

.card-row-column-img:nth-child(1) {
    max-height: 55%;
}

.card-row-column-button {
    background-color: rgb(255, 186, 57);
    padding: 6px;
    font-weight: 500;
    font-family: 'Staatliches',sans-serif;
    color: black;
    border-radius: 3px;
    font-size: 16px;
    border: none;
}

.card-row-icon {
    font-size: 57px;
    color:rgb(255, 186, 57);
}

@media screen and (max-width: 992px) {
    .card-row {
        flex-direction: column;
    }

    .card-row-column {
        padding: 35px;
        margin: 4px;
        --box-shadow: 1px 1px 1px 1px rgba(255, 186, 57,0.45);
    }

    .card-row-title {
        font-size: 25px;
        padding: 0px 0px 0px 0px;
    }
}