.card-info-body {
    width: 100%;
    font-family: 'Staatliches', sans-serif;
    flex: 1 0 auto;
    background-color: rgb(24, 23, 31);
    margin-bottom: 250px;
}

.card-info-row {
    display: flex;
    margin: auto;
    width: 70%;
    max-width: 1300px;
    position: relative;
    align-items: center;
    top: 50px;
    background-color: rgb(24, 23, 31);
}

.card-info-row-column {
    background-color: rgb(24, 23, 31);
}
  
.card-info-row-column:nth-child(2) {
    height: 80%;
}

.card-info-row-column:nth-child(1) {
    flex: 1;
    background-color: rgb(24, 23, 31);
    height: 80%;
    min-width: 550px;
    -webkit-box-shadow: 2px 7px 16px 0px rgba(177, 96, 96, 0.15);
    -moz-box-shadow: 2px 7px 16px 0px rgba(0,0,0,0.25);
    box-shadow: 2px 7px 16px 0px rgba(0,0,0,0.25);
}

.card-info-column-title {
    font-size: 32px;
    font-family: 'Staatliches', sans-serif;
    color: seashell;
    font-weight: 700;
    width: 50%;
    margin: auto;
    padding-top: 45px;
    padding-bottom: 10px;
    text-align: center;
}

.card-info-column-text {
    white-space: pre-line;
    font-size: 17px;
    line-height: 1.4;
    color: #a5a5a4;
    font-weight: 500;
    text-align: left;
    width: 95%;
    margin: auto;
    font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
}

.card-info-column-shoplink {
    white-space: pre-line;
    font-size: 25px;
    line-height: 1.4;
    color: orange;
    font-weight: 500;
    text-align: center;
    width: 85%;
    border-color:rgb(70, 64, 60);
    margin: auto;
}

.card-info-column-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    -webkit-box-shadow: 2px 7px 16px 0px rgba(177, 96, 96, 0.15);
    -moz-box-shadow: 2px 7px 16px 0px rgba(0,0,0,0.25);
    box-shadow: 2px 7px 16px 0px rgba(0,0,0,0.25);
}

@media screen and (max-width: 1200px) {

    .card-info-body {
        display: block;
    }

    .card-info-row {
        flex-direction: column;
        top: 70px;
        width: 75%;
    }

    .card-info-row-column:nth-child(1) {
        margin-bottom: 10px;
        width: 94%;
        min-width: 0px;
        order: 1;
    }

    .card-info-row-column:nth-child(2) {
        width: 94%;
        order: 2;
    }

    .card-info-column-title {
        padding-top: 20px;
    }

    .card-info-column-text {
        font-size: 14px;
        --margin-bottom: 20px;
    }
}

@media screen and (max-width: 992px) {

    .card-info-row {
        width: 80%;
    }

    .card-info-column-title {
        font-size: 24px;
    }

    .card-info-column-text {
        width: 89%;
    }
}

@media screen and (max-width: 500px) {

    .card-info-row {
        width: 85%;
    }
}