.shop-body {
  width: 100%;
  font-family: "Staatliches", sans-serif;
  flex: 1 0 auto;
  margin-bottom: 150px;
}

.shop-background-img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #f2f2f2;
}

figure {
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.shop-background-img-text {
  position: absolute;
  top: 30%;
  text-align: center;
  width: 100%;
}

.shop-background-img-text h2 {
  color: white;
  font-size: 43px;
  width: 100%;
}

.shop-background-img-text p {
  color: white;
  font-size: 24px;
  width: 100%;
}

.shop-ticets-title {
  width: 100%;
  text-align: center;
  font-size: 32px;
  padding-top: 30px;
}

.shop-item-container {
  padding-top: 20px;
  max-width: 950px;
  width: 80%;
  margin-left: auto;
  padding-bottom: 60px;
  margin-right: auto;
  flex: 1;
}

.basket-continue-shopping {
  position: absolute;
  top: 20px;
  right: 40px;
  padding: 7px;
  text-align: center;
  border-radius: 3px;
  font-family: "Staatliches", sans-serif;
  font-size: 16px;
  background-color: #18171f;
  color: white;
  outline: none;
  text-decoration: none;
}

.shop-item-container-body {
  background-color: white;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 50px;
  margin-bottom: 150px;
  padding-top: 50px;
  border-radius: 3px;
  position: relative;
  -webkit-box-shadow: -1px 2px 11px 0px rgba(50, 50, 50, 0.55);
  -moz-box-shadow: -1px 2px 11px 0px rgba(50, 50, 50, 0.55);
  box-shadow: -1px 2px 11px 0px rgba(50, 50, 50, 0.55);
}

.shop-item-container-body h1 {
  font-size: 27px;
  font-weight: 500;
  margin: 0;
  top: 22px;
  position: absolute;
  left: 40px;
}

.shop-item-container-body-button {
  float: right;
  padding: 7px;
  border-radius: 3px;
  font-family: "Staatliches", sans-serif;
  font-size: 16px;
  background-color: rgb(255, 186, 57);
  color: black;
  outline: none;
  border: none;
  cursor: pointer;
}
.shop-item-container-body-button:disabled {
  opacity: 0.7;
}

.shop-footer {
  flex: 1;
  display: flex;
  background-color: orange;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .shop-item-container-body {
    margin-left: 40px;
    margin-right: 40px;
  }

  .shop-item-container {
    width: 100%;
  }

  .shop-footer {
    position: relative !important;
  }

  .shop-item-container-body h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 500px) {
  .shop-item-container-body {
    margin-left: 28px;
    margin-right: 28px;
  }

  .shop-item-container-body h1 {
    font-size: 22px;
  }

  .shop-item-container-body-button {
    margin-right: 5px;
  }
}

@media screen and (max-width: 396px) {
  .shop-item-container-body {
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 13px;
    margin-right: 13px;
  }

  .shop-item-container-body h1 {
    font-size: 22px;
    left: 20px;
  }

  .shop-item-container-body-button {
    margin-right: 5px;
  }

  .basket-continue-shopping {
    right: 20px;
  }
}
