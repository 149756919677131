.about-us-body {
    width: 100%;
}

.about-us-row {
    display: flex;
    margin: auto;
    max-width: 1400px;
    position: relative;
    align-items: center;
    height: 600px;
    padding-bottom: 50px;
}
  
.about-us-row-column:nth-child(2) {
    flex: 2;
    height: 100%;
}

.about-us-row-column:nth-child(1) {
    flex: 1;
    background-color: rgb(24, 23, 31);
    box-shadow: 2px 7px 16px 0px rgba(0,0,0,0.35);
    height: 100%;
    min-width: 550px;
}

.about-us-column-title {
    font-size: 28px;
    font-family: 'Staatliches', sans-serif;
    color: seashell;
    font-weight: 700;
    width: 40%;
    margin: auto;
    padding-top: 45px;
    padding-bottom: 10px;
}

.about-us-column-text {
    white-space: pre-line;
    font-size: 17px;
    line-height: 1.5;
    color: seashell;
    font-weight: 500;
    text-align: left;
    width: 80%;
    border-color:rgb(70, 64, 60);
    margin: auto;
    padding-top: 15px;
    padding-bottom: 30px;
}

.about-us-column-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    box-shadow: 0px 0px 7px 7px rgba(245, 175, 47, 0.85);
}

@media screen and (max-width: 1200px) {
    .about-us-row {
        height: 550px;
    }
    .about-us-row-column:nth-child(1) {
        min-width: 500px;
    }
    .about-us-column-title {
        padding-top: 20px;
    }
}

@media screen and (max-width: 992px) {
    .about-us-row {
        flex-direction: column;
        height: 50%;
    }
    .about-us-column-text {
        width: 85%;
    }
    .about-us-row-column:nth-child(1) {
        width: 94%;
        min-width: 0px;
        order: 2;
    }
    .about-us-row-column:nth-child(2) {
        width: 94%;
        order: 1;
    }
}
