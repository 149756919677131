.shop-item-container-header {
    background-color: #18171f;
    opacity: 0.95;
    width: 100%;
    padding-top: 10px;
    height: 50px;
    padding-bottom: 10px;
    color: black;
    font-size: 32px;
    position: relative;
    z-index: 10;
    border-radius: 3px;
    margin-top: 120px;
}

.shop-item-container-header h3 {
    position: absolute;
    left: 42px;
    top: 22px;
    color: white;
    font-weight: 500;
    font-size: 24px;
    margin: 0;
}

.shop-item-container-header i {
    outline: none;
    border: none;
    margin: 6px;
    border-radius: 5px;
    padding: 6px;
    background-color: transparent;
    vertical-align: middle;
    font-style: normal;
    text-align: center;
    color: white;
    font-size: 23px;
    cursor: pointer;
}

.shop-item-container-header i:nth-child(3) {
    float: right;
}

.shop-item-container-header i:nth-child(2) {
    float: right;
    margin-right: 40px;
}

.shop-item-container-header i:hover {
    text-decoration: underline;
}

.shop-item-container-header i.active {
    color: rgb(255, 186, 57);
}

.shop-item-container-header i.disabled {
    pointer-events: none;
    color: #999;
}

.shop-item-container-header p {
    display: inline;
    top: 10px;
    vertical-align: top;
}

.shop-item-container-header .contact-icon {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 50%;
    background-color: white;
    color: #333;
}

.shop-item-container-header .cart-icon {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 50%;
    background-color: white;
    color: #333;
}

@media screen and (max-width: 800px) {

    .shop-item-container-header {
        width: 89%;
        margin-top: 95px;
        margin-left: 40px;
    }

    .shop-item-container-header h3 {
        font-size: 20px;
    }

    .shop-item-container-header i {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 500px) {

    .shop-item-container-header {
        width: 87%;
        margin-left: 26px;
    }

    .shop-item-container-header h3 {
        left: 20px;
        font-size: 20px;
    }
}

@media screen and (max-width: 396px) {

    .shop-item-container-header {
        width: 93%;
        margin-left: 13px;
    }

    .shop-item-container-header h3 {
        left: 10px;
        font-size: 20px;
    }

    .shop-item-container-header i:nth-child(2) {
        margin-right: 20px;
    } 
}